import "@fontsource/raleway/600.css";
import "@fontsource/raleway/700.css";
import React, { lazy } from "react";
import ReactDOM from "react-dom/client";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";

const AppContainer = lazy(() => import("./AppContainer"));
const CDRReadyAppContainer = lazy(() => import("./CdrReadyAppContainer"));

declare global {
  interface Window {
    appConfig: {
      instrumentationKey?: string;
      b2cName: string;
      b2cHostname: string;
      b2cWebClientClientId: string;
      b2cWebApiClientId: string;
      version: string;
      isBusinessDisclosureFlowEnabled: boolean;
      businessAccountNominatedRepresentativeLink: string;
      isProd: boolean;
      featureFlagAdrisMaintenance: boolean;
      b2cSignUpSignInPolicy: string;
      b2cPasswordResetPolicy: string;
      b2cEditProfilePolicy: string;
      // This is populated in index.html
      isCdrReadyPage: string;
    };
  }
}

const fetchConfig = async (): Promise<void> => {
  const response = await fetch("/api/v1/config");
  window.appConfig = { ...window.appConfig, ...(await response.json()) };
};

(async () => {
  await fetchConfig();

  const { instrumentationKey } = window.appConfig || {};

  if (process.env.NODE_ENV === "production" && instrumentationKey) {
    const reactPlugin = new ReactPlugin();
    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey,
        enableAutoRouteTracking: true,
        // @ts-ignore
        extensions: [reactPlugin],
      },
    });
    appInsights.loadAppInsights();
  }

  if (window.appConfig.isCdrReadyPage) {
    ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
      <CDRReadyAppContainer />
    );
  } else {
    ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
      <AppContainer />
    );
  }
})();
